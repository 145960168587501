import React ,{useState, useEffect}from 'react';
import { BrowserRouter } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import { NavBar } from './pages/Modules/NavBar/NavBar'
import { Home } from './pages/Home/Home'
import { GalleryPage } from './pages/Gallery/Gallery'
import { Contact } from './pages/Contact/Contact'
import { Footer } from './pages/Modules/Footer/Footer'
import { NavBarMobile } from './pages/Modules/NavBarMobile/NavBarMobile'
import  './App.scss'


function App() {

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  console.log(isMobile)

  useEffect(() => {
    // Funkcja do sprawdzania szerokości okna
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    // Nasłuchiwanie zmiany rozmiaru okna
    window.addEventListener('resize', handleResize);

    // Usunięcie nasłuchiwania przy odmontowywaniu komponentu
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  

  return (
  <div className='all'>
    <BrowserRouter>
    {isMobile ? <NavBarMobile/> : <NavBar/>}
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/realizacje' element={<GalleryPage/>}/>
        <Route path='/kontakt' element={<Contact/>}/>
      </Routes>
      <Footer/>
    </BrowserRouter>
  </div>

  );
}

export default App;
