import './NavBar.scss'
import { NavLink } from "react-router-dom"
import { useState } from 'react';
import Zoom from 'react-reveal/Zoom';

export const NavBar = () =>{

  const [ open, setOpen ] =useState(false)

  return(
    <div className='container-fluid  navbar-module'>
      <div className='row'>
        <div className='col-7 logo-and-navigate '>
          <NavLink to={'/'}>
            <Zoom>
              <h1>StolBud - meble z pasją</h1>
            </Zoom>
          </NavLink>
        </div>
        <div className='col-5 logo-and-navigate navigate '>
          <div className='row p-1'>
            <div className='col-4  '>
              <NavLink to={'/'}>
                <Zoom>
                  <p className='text-center '>Główna</p>
                </Zoom>
              </NavLink>
            </div>
            <div className='col-4 '>
              <NavLink to={'/realizacje'}>
                <Zoom>
                  <p className='text-center'>Realizacje</p>
                </Zoom>
              </NavLink>
            </div>
            <div className='col-4'>
              <NavLink to={'/kontakt'}>
                <Zoom>
                  <p className='text-center'>Kontakt</p>
                </Zoom>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

