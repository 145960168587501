import './Contact.scss'

export const Contact = () =>{

  return(
    <div className='container-fill contact-module'>
      <div className='col gallery-col' >
        <div className='img-txt-box'>
          <div className='img-box'>
            <img src='kontakt.jpg'/>
          </div>
          <div className='txt-box'>
            <h1 className="animate__animated animate__bounce">Skontaktuj się z nami już dziś!</h1>
            <p className='animate__animated animate__backInLeft'>
              Twoje marzenia o idealnych meblach są w zasięgu ręki. Niezależnie od tego, czy masz gotowy pomysł, czy potrzebujesz profesjonalnej porady,
              nasz zespół jest tutaj, aby pomóc Ci każdym krokiem. Daj nam znać, jak możemy wspierać Twój projekt lub pomóc rozwiązać wszelkie wyzwania.
            </p>
          </div>
        </div>
      </div>
      <div className='info'>
        <div class="container mt-5"> 
          <div class="row justify-content-center"> 
            <div class="col-md-8 text-center"> 
              <div class="info-txt">
                <h2 class="mb-4">Gotowi na zmianę? Rozpocznijmy współpracę.</h2> 
                <p>
                  Twój projekt zasługuje na najlepszą opiekę i wykonanie. Zadzwoń lub napisz do nas - odpowiemy
                  na wszystkie Twoje pytania i przedstawimy, jak możemy wspólnie pracować nad realizacją Twojego wymarzonego projektu.
                </p>
              </div>
            </div>
          </div>
          <div class="row mt-4"> 
            <div class="col-md-4 text-center d-flex justify-content-center align-items-center"> 
              <h1>StolBud</h1>
            </div>
            <div class="col-md-4 text-center">
              <h3>pawel8730@interia.pl</h3>
              <p>Nie krępuj się, jeżeli potrzebujesz informacji, skontaktuj się z nami.</p>
            </div>
            <div class="col-md-4 text-center"> 
              <h3>Telefon</h3>
              <h4>535371409</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}