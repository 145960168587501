import './Gallery.scss'
import "yet-another-react-lightbox/styles.css";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images"
import { useState, useCallback } from 'react';
import { photos } from "./photos";

export const GalleryPage = () => {

  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return(
    <div className='container-fill gallery-module'>
      <div className='row margin'>
        <div className='col gallery-col' >
          <div className='img-txt-box'>
            <div className='img-box'>
              <img src='gallery.png'/>
            </div>
            <div className='txt-box'>
              <h1 class="animate__animated animate__bounce">ODKRYJ NASZE REALIZACJE</h1>
              <p className='animate__animated animate__backInLeft'>
                Zapraszamy do naszej galerii, gdzie prezentujemy dumę naszej pracy – projekty, które przekształciliśmy z wizji w rzeczywistość. Każde zdjęcie w naszej 
                galerii to nie tylko dowód na nasze umiejętności stolarskie, ale także źródło inspiracjidla Twojego przyszłego projektu. Od klasycznych form po 
                nowoczesne konstrukcje, nasze realizacje odzwierciedlają pasję i precyzję, z jaką podchodzimy do każdego zadania.
              </p>
            </div>
            <div className='row gallery-txt'>
              <h1>GALERIA</h1>
            </div>
            <div className='row gallery-box'>
              <div className='col  gallery'>
                <Gallery photos={photos} onClick={openLightbox} />
                <ModalGateway>
                  {viewerIsOpen ? (
                    <Modal onClose={closeLightbox}>
                      <Carousel
                        currentIndex={currentImage}
                        views={photos.map(x => ({
                          ...x,
                          srcset: x.srcSet,
                          caption: x.title
                        }))}
                      />
                    </Modal>
                  ) : null}
                </ModalGateway>
              </div>
            </div>       
          </div> 
        </div>
      </div>
    </div>
  )
}