export const photos = [
  {
    src: 'galeria/1.jpg',
    width: 4,
    height: 3
  },
  {
    src: "galeria/2.jpg",
    width: 1,
    height: 1
  },
  {
    src: "galeria/3.jpg",
    width: 3,
    height: 4
  },
  {
    src: "galeria/4.jpg",
    width: 3,
    height: 4
  },
  {
    src: "galeria/5.jpg",
    width: 3,
    height: 4
  },
  {
    src: "galeria/6.jpg",
    width: 4,
    height: 3
  },
  {
    src: "galeria/7.jpg",
    width: 3,
    height: 4
  },
  {
    src: "galeria/8.jpg",
    width: 4,
    height: 3
  },
  {
    src: "galeria/9.jpg",
    width: 4,
    height: 3
  },
  {
    src: "galeria/10.jpg",
    width: 3,
    height: 4
  },
  {
    src: "galeria/11.jpg",
    width: 4,
    height: 3
  },
  {
    src: "galeria/12.jpg",
    width: 3,
    height: 4,
  },
  {
    src: "galeria/13.jpg",
    width: 4,
    height: 3
  },
  {
    src: "galeria/14.jpg",
    width: 4,
    height: 3
  },
  {
    src: "galeria/15.jpg",
    width: 4,
    height: 3
  },
  {
    src: "galeria/16.jpg",
    width: 4,
    height: 3
  },
  {
    src: "galeria/17.jpg",
    width: 4,
    height: 3
  },
  {
    src: "galeria/18.jpg",
    width: 4,
    height: 3
  },
  {
    src: "galeria/19.jpg",
    width: 4,
    height: 3
  },
  {
    src: "galeria/20.jpg",
    width: 4,
    height: 3
  },
  {
    src: "galeria/21.jpg",
    width: 4,
    height: 3
  }
];