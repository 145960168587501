import './NavBarMobile.scss'
import { NavLink } from "react-router-dom"
import { GiHamburgerMenu } from "react-icons/gi";
import { useState } from 'react';
import Zoom from 'react-reveal/Zoom';
import 'animate.css';

export const NavBarMobile = () =>{

  const [ open, setOpen ] =useState(false)
  
  return(
    <div className='container-fluid  navbar-mobile-module'>
      <div className='logo'>
        <NavLink to={'/'}>
          <Zoom>
            <h1>StolBud - meble z pasją</h1>
          </Zoom>
        </NavLink>
      </div>
      <div className='hamburger'>
        <Zoom>
          <p><GiHamburgerMenu size={25} onClick={e=>setOpen(!open)}/></p>
        </Zoom>  
      </div>
      { open && <div className=' navigate '>
        <div className='col-4  '>
          <NavLink to={'/'}>
            <Zoom>
              <p onClick={e=>setOpen(!open)}>Główna</p>
            </Zoom>
          </NavLink>
        </div>
        <div className='col-4 '>
          <NavLink to={'/realizacje'}>
            <Zoom>
              <p onClick={e=>setOpen(!open)}>Realizacje</p>
            </Zoom>
          </NavLink>
        </div>
        <div className='col-4'>
          <NavLink to={'/kontakt'}>
            <Zoom>
              <p onClick={e=>setOpen(!open)}>Kontakt</p>
            </Zoom>
          </NavLink>
        </div>
      </div>}
    </div>
  )
}