import './Implementation.scss'

export const Implementation = () =>{

  return(
    <div className='container implementation-module'>
      <div>
        <h1 className='text-center'>PROCES REALIZACJI</h1>
      </div>
      <div className='row implementation-steps animate__animated animate__backInRight'>
        <div className='col-12 col-md order-1 order-md-2'>
          <h2>1. Kontakt</h2>
          <h3>Rozpoczynamy od Twojego Pomysłu</h3>
        </div>
        <div className='col img-box order-2 order-md-3'>
          <img src='/1.jpg'/>
        </div>
        <div className='col-12 col-md order-3 order-md-1'>
          <p>
            Wszystko zaczyna się od Ciebie – skontaktuj się z nami telefonicznie, mailowo lub poprzez formularz
            na naszej stronie internetowej. Podziel się swoją wizją i potrzebami. Nasi eksperci są gotowi
            wysłuchać Twoich pomysłów i odpowiedzieć na wszelkie pytania.
          </p>
        </div>
      </div>
      <div className='row implementation-steps align-items-center animate__animated animate__backInLeft'>
        <div className='col-12 col-md align-self-start order-md-3 order-1'>
          <h2>2. Wizyta u klienta i zebranie wymiarów</h2>
          <h3>Dokładność na Pierwszym Miejscu</h3>
        </div>
        <div className='col-12 col-md img-box order-md-2 order-2'>
          <img src='/1.png'/>
        </div>
        <div className='col-12 col-md align-self-center  order-md-1 order-3 '>
          <p>
            Po wstępnym kontakcie, umawiamy się na wizytę w Twoim domu lub miejscu, gdzie mają znaleźć się meble.
            Dokonujemy precyzyjnych pomiarów przestrzeni, aby upewnić się, że nasze projekty będą idealnie dopasowane do Twojego wnętrza.
          </p>
        </div>
      </div>
      <div className='row implementation-steps animate__animated animate__backInRight'>
        <div className='col-12 col-md  align-self-center order-1 order-md-3 '>
          <h2>3. Projekt i Wycena</h2>
          <h3>Twoja Decyzja, Nasze Realizacje</h3>
        </div>
        <div className='col-12 col-md img-box order-2 order-md-1'>
          <img src='/2.jpg'/>
        </div>
        <div className='col-12 col-md align-self-end order-3 order-md-2'>
          <p>
            Po otrzymaniu projektu i wyceny, masz czas na przemyślenia i ewentualne wprowadzenie zmian. Jesteśmy otwarci na Twoje sugestie
            – chcemy, aby meble były dokładnie takie, jak sobie wymarzyłeś.
          </p>
        </div>
      </div>
      <div className='row implementation-steps animate__animated animate__backInLeft'>
        <div className='col-12 col-md align-self-start'>
          <h2>4. Akceptacja Projektu</h2>
          <h3>Twoja Decyzja, Nasze Realizacje</h3>
        </div>
        <div className='col-12 col-md img-box '>
          <img src='/2.png'/>
        </div>
        <div className='col-12 col-md align-self-center'>
          <p>
            Po otrzymaniu projektu i wyceny, masz czas na przemyślenia i ewentualne wprowadzenie zmian. Jesteśmy otwarci na Twoje sugestie 
            – chcemy, aby meble były dokładnie takie, jak sobie wymarzyłeś.
          </p>
        </div>
      </div>
      <div className='row implementation-steps animate__animated animate__backInRight'>
        <div className='col-12 col-md align-self-center order-1 order-md-2'>
          <h2>5. Realizacja Zamówienia</h2>
          <h3>Precyzja i Rzemiosło</h3>
        </div>
        <div className='col-12 col-md img-box order-1 order-md-3'>
          <img src='/4.png'/>
        </div>
        <div className='col-12 col-md align-self-end order-1 order-md-1'>
          <p>
            Gdy projekt zostanie zaakceptowany i szczegóły doprecyzowane, przystępujemy do realizacji zamówienia. Nasze meble tworzone są z najwyższej
            jakości materiałów, z dbałością o każdy, nawet najmniejszy detal.
          </p>
        </div>
      </div>
      <div className='row implementation-steps animate__animated animate__backInLeft'>
        <div className='col-12 col-md align-self-center '>
          <h2>6. Dostawa i Montaż</h2>
          <h3>Ostatni Krok: Ciesz się Nową Przestrzenią</h3>
        </div>
        <div className='col-12 col-md img-box'>
          <img src='/5.png'/>
        </div>
        <div className='col-12 col-md'>
          <p>
            Po zakończeniu produkcji, umawiamy się na dostawę i montaż mebli w Twoim domu. Nasi fachowcy zapewniają, że wszystko zostanie zamontowane szybko,
            sprawnie i bez najmniejszych problemów.
          </p>
        </div>
      </div>
    </div>
  )
}