import "./Footer.scss"

export const Footer = () =>{

  return(
    <div class="container mt-1 footer-module"> 
      <div class="row justify-content-center"> 
        <div class="col-md-8 text-center"> 
          <div class="info-txt">
            <h4 class="mb-4">Dowiedz się więcej o naszych usługach.</h4> 
          </div>
        </div>
      </div>
      <div class="row"> 
        <div class="col-md-4 col text-center d-flex justify-content-center align-items-center"> 
          <h3>StolBud</h3>
        </div>
        <div class="col-md-4 col text-center">
          <h5>E-mail</h5>
          <h6>pawel8730@interia.pl</h6>
        </div>
        <div class="col-md-4 col text-center"> 
          <h5>Telefon</h5>
          <h6>535371409</h6>
        </div>
      </div>
    </div>
  )
}