import './Home.scss'
import { Implementation } from './Implementation/Implementation'

export const Home = () =>{

  return(
    <div className="container-fluid home-module p-0">
      <div className="img-and-txt-box">
        <div className="img-box">
          <img src="/3.png" alt="zdj"/>
        </div>
        <div className="txt-box">
          <h1 class="animate__animated animate__bounce" >TWORZYMY PRZESTRZEŃ MARZEŃ</h1>
          <p className='animate__animated animate__backInLeft'>
            W naszym zakładzie, tworzymy więcej niż meble – kreujemy przestrzenie, w których każdy detal ma znaczenie. Specjalizujemy się w produkcji
            mebli na wymiar, dostosowanych do Twoich indywidualnych potrzeb i oczekiwań. Nasz zespół doświadczonych rzemieślników łączy tradycyjne techniki 
            stolarskie z nowoczesnym designem, aby każdy projekt był wyjątkowy i na miarę Twoich marzeń. Od eleganckich kuchni po stylowe garderoby, każdy 
            mebel jest dopracowany do perfekcji, odzwierciedlając Twój osobisty styl i dodając funkcjonalności do Twojego życia. Zapraszamy do współtworzenia przestrzeni, która opowiada Twoją historię.
          </p>
        </div>
      </div>
      <Implementation/>
    </div>
  )
}